import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceTableRuleType, TablePricesDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList } from '@tiendanube/components';
import { BoxPackedIcon } from '@tiendanube/icons';
import CustomerPriceTablesErrorState from 'domains/Customers/Customers/pages/CustomerDetailsPage/components/CustomerPriceTables/components/CustomerPriceTablesErrorState';
import CustomerPriceTablesItem from 'domains/Customers/Customers/pages/CustomerDetailsPage/components/CustomerPriceTables/components/CustomerPriceTablesItem';
import CustomerPriceTablesSkeleton from 'domains/Customers/Customers/pages/CustomerDetailsPage/components/CustomerPriceTables/components/CustomerPriceTablesSkeleton';
import useCustomerTablePrices from 'domains/Customers/CustomerTablePrices/hooks/useCustomerTablePrices';
import useTablePrices from 'domains/Customers/TablePrices/hooks/useTablePrices';

interface CustomerPriceTablesProps {
  customerId: string;
}

export default function CustomerPriceTables({
  customerId,
}: Readonly<CustomerPriceTablesProps>) {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const tPath = 'customerDetails.customerPriceTables';

  // TODO: Remove this mock once fetchTablePrices returns 200.
  const tablePricesData = useMemo<TablePricesDto[]>(
    () => [
      {
        id: '1',
        name: 'Tabela de preço Padrão 88',
        defaultValue: '71',
        active: true,
        ruleType: 'allProducts' as PriceTableRuleType,
      },
      {
        id: '5',
        name: 'Tabela de preço',
        defaultValue: '71',
        active: true,
        ruleType: 'allProducts' as PriceTableRuleType,
      },
    ],
    [],
  );
  const { tablePricesStatus, fetchTablePrices } = useTablePrices();
  const {
    customerTablePricesStatus,
    customerTablePricesData,
    fetchCustomerTablePrices,
    resetCustomerTablePrices,
  } = useCustomerTablePrices(customerId);

  useEffect(() => {
    fetchTablePrices();
    fetchCustomerTablePrices();

    return () => {
      resetCustomerTablePrices();
    };
  }, [fetchTablePrices, fetchCustomerTablePrices, resetCustomerTablePrices]);

  const assignedPriceTables = useMemo(
    () =>
      tablePricesData.filter((data) =>
        customerTablePricesData.find(
          (assignedData) => assignedData === data.id,
        ),
      ),
    [customerTablePricesData, tablePricesData],
  );

  if (
    tablePricesStatus.isSuccess &&
    customerTablePricesStatus.isSuccess &&
    assignedPriceTables.length === 0
  ) {
    return null;
  }

  const headerLabel = {
    id: 'priceTablesHeaderLabel',
    label: t(`${tPath}.label`),
    appearance: 'primary' as const,
    icon: BoxPackedIcon,
  };

  if (tablePricesStatus.isError || customerTablePricesStatus.isError) {
    const onRetry = tablePricesStatus.isError
      ? fetchTablePrices
      : fetchCustomerTablePrices;
    return (
      <CustomerPriceTablesErrorState
        headerLabel={headerLabel}
        onRetry={onRetry}
      />
    );
  }

  if (tablePricesStatus.isLoading || customerTablePricesStatus.isLoading) {
    return <CustomerPriceTablesSkeleton headerLabel={headerLabel} />;
  }

  return (
    <Card title={t(`${tPath}.title`)} headerLabel={headerLabel}>
      <DataList>
        {assignedPriceTables.map((priceTable) => (
          <CustomerPriceTablesItem
            priceTable={priceTable}
            key={priceTable.id}
          />
        ))}
      </DataList>
    </Card>
  );
}
